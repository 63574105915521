<template>
  <div ref="gantt" :style="{ height: `${height}px` }" />
</template>

<script>
import { gantt } from 'dhtmlx-gantt';
import moment from 'moment/moment';
import { mapGetters } from 'vuex';

export default {
  name: 'FourDGanttChart',
  props: {
    plannings: {
      type: Array,
      required: true,
    },
    sliderDate: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      height: 100,
      dateMarkerId: undefined,
      isInitialized: false,
      marker: {
        css: 'highlight', //a CSS class applied to the marker
      },
      deInitialize: () => undefined,
    };
  },
  computed: {
    ...mapGetters(['fourDActivities']),
    activities() {
      return this.fourDActivities
        .filter((a) =>
          this.plannings.map((item) => item.id).includes(a.planningId)
        )
        .map((a) => ({
          ...a,
          start_date: moment(a.start_date),
          end_date: moment(a.end_date),
        }));
    },
  },
  watch: {
    sliderDate(sliderDate) {
      if (!!sliderDate) {
        const date = sliderDate.toDate();
        const markerId = gantt.addMarker(this.marker);

        gantt.showDate(date);
        gantt.getMarker(markerId).start_date = date;
        gantt.updateMarker(markerId);
      }
    },
    plannings() {
      this.invalidate();
    },
    activities(value) {
      this.invalidate();
    },
  },
  mounted: function () {
    this.deInitialize = this.initialize();
  },
  unmounted: function () {
    this.deInitialize();
  },
  methods: {
    initialize() {
      const self = this;
      const onTaskClickId = gantt.attachEvent(
        'onTaskClick',
        function () {
          self.$emit('clickActivity', ...arguments);
        },
        undefined
      );

      gantt.config.show_errors = false;
      gantt.config.fit_tasks = true;
      gantt.config.readonly = true;
      gantt.config.columns = [
        {
          name: 'text',
          label: 'Name',
          width: 156,
          resize: true,
        },
        {
          name: 'start_date',
          label: 'Start',
          align: 'center',
          resize: true,
          width: 90,
        },
        {
          name: 'end_date',
          label: 'End',
          align: 'center',
          resize: true,
          width: 90,
        },
        {
          name: 'duration',
          label: 'Duration',
          align: 'center',
          width: 70,
        },
      ];

      gantt.templates.scale_cell_class = function (date) {
        if (date.getDay() === 0 || date.getDay() === 6) {
          return 'weekend';
        }
      };
      gantt.templates.timeline_cell_class = function (task, date) {
        if (date.getDay() === 0 || date.getDay() === 6) {
          return 'weekend';
        }
      };

      gantt.plugins({
        marker: true,
      });

      gantt.init(this.$refs.gantt);
      this.isInitialized = true;
      this.invalidate();

      return () => {
        gantt.detachEvent(onTaskClickId);
      };
    },
    invalidate() {
      if (this.isInitialized) {
        const data = this.activities
          .sort((l, r) => l.start_date.diff(r.start_date))
          .map((a) => ({
            ...a,
            text: a.name,
            start_date: a.start_date.format('DD-MM-YYYY'),
            end_date: a.end_date.format('DD-MM-YYYY'),
            progress: 0,
            open: true,
          }));
        const links = this.activities
          .map((a) => ({
            ...a,
            successor: this.activities.find(
              (aa) => aa.activityId === a.successorId
            ),
          }))
          .filter((a) => !!a.successor)
          .map((a, id) => ({
            id,
            source: a.id,
            target: a.successor.id,
            type: gantt.config.links.finish_to_start,
          }));

        gantt.clearAll();
        gantt.parse({ data, links });
      }
    },
  },
};
</script>

<style scoped>
@import '../../../css/dhtmlxgantt.css';
</style>
