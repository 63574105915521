<template>
  <div v-if="fourDStatus === 'success'" class="d-flex flex-column">
    <forge-viewer
      ref="forge-viewer"
      :client="fourDClient"
      :models="fourDModels"
      :extensions="['Autodesk.DocumentBrowser', 'Autodesk.VisualClusters']"
      :extension-options="[]"
      :custom-extensions="['RfisExtension']"
      :ant-toolbar-options="{
        viewType: {
          display: true,
          enabled: false,
        },
        performanceMode: {
          display: true,
          enabled: true,
        },
        models: {
          display: true,
          enabled: false,
        },
        antTable: {
          display: true,
          enabled: false,
        },
        ghosting: {
          display: true,
          enabled: false,
        },
        modelTree: {
          display: true,
          enabled: false,
        },
        clearIsolation: {
          display: true,
          enabled: false,
        },
        objectProperties: {
          display: true,
          enabled: false,
        },
        sidebar: {
          display: true,
          enabled: true,
          width: 400,
        },
      }"
      class="flex-1"
      @modelsRendered="viewerLoaded"
    >
      <template #ant-forge-toolbar-actions>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              tile
              icon
              v-bind="attrs"
              :color="toggle4DPlanning ? 'primary' : ''"
              data-v-step="FourD_S0"
              v-on="on"
              @click="
                toggle4DPlanning = !toggle4DPlanning;
                $refs['forge-viewer'].resizeViewEvent();
              "
            >
              <v-icon> mdi-calendar </v-icon>
            </v-btn>
          </template>
          <span>4D Planning</span>
        </v-tooltip>
      </template>
      <template #sidebar-top>
        <div v-if="toggle4DPlanning" class="d-flex flex-column flex-1">
          <div class="d-flex">
            <ant-input
              label="Planning"
              class="flex-1"
              style="margin-top: 0 !important"
              is-optional
            >
              <template #input-field>
                <v-select
                  v-model="editPlanning"
                  :items="modelPlannings"
                  data-v-step="FourD_S4"
                  item-text="name"
                  hide-details
                  filled
                  single-line
                  dense
                  clearable
                  return-object
                  placeholder="Choose planning to edit"
                  @click:clear="editActivity = null"
                />
              </template>
            </ant-input>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  :ripple="false"
                  class="ml-2 mt-5"
                  :disabled="
                    fourDModels.filter((m) => !!m.enabled).length !== 1
                  "
                  v-on="on"
                  @click="newPlanningDialog = true"
                >
                  <v-icon class="ant-icon"> mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Create new planning for this 3D model</span>
            </v-tooltip>
          </div>

          <v-dialog
            v-model="newPlanningDialog"
            max-width="400"
            @click:outside="
              newPlanningDialog = false;
              newPlanningItem = {};
            "
            @keydown.esc="
              newPlanningDialog = false;
              newPlanningItem = {};
            "
            @keydown.enter="createPlanning"
          >
            <v-card>
              <v-card-title class="justify-center text-uppercase headline">
                Create new planning
              </v-card-title>
              <v-divider />
              <div class="px-10 pb-5">
                <ant-input label="Name" :is-optional="false">
                  <template #input-field>
                    <v-text-field
                      v-model="newPlanningItem.name"
                      counter="45"
                      placeholder="Name"
                      maxlength="45"
                      hide-details
                      dense
                      filled
                      single-line
                    />
                  </template>
                </ant-input>
              </div>

              <v-card-actions class="ant-border-top ant-dialog-actions-bg">
                <v-spacer />

                <v-btn
                  color="primary"
                  text
                  small
                  @click="
                    newPlanningItem = {};
                    newPlanningDialog = false;
                  "
                >
                  Cancel
                </v-btn>

                <v-btn
                  color="primary"
                  small
                  elevation="0"
                  @click="createPlanning"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <div class="d-flex mt-5">
            <ant-input
              label="Activity"
              is-optional
              class="flex-1"
              style="margin-top: 0 !important"
            >
              <template #input-field>
                <v-autocomplete
                  v-model="editActivityListModel"
                  :disabled="!editPlanning"
                  :items="editActivities"
                  item-text="name"
                  data-v-step="FourD_S5"
                  return-object
                  hide-details
                  filled
                  single-line
                  dense
                  clearable
                  placeholder="Choose activity to edit"
                />
              </template>
            </ant-input>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  :ripple="false"
                  :disabled="!editPlanning"
                  class="ml-2 mt-5"
                  v-on="on"
                  @click="setupNewActivity"
                >
                  <v-icon class="ant-icon"> mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Create new activity</span>
            </v-tooltip>
          </div>

          <v-form
            v-if="editActivity"
            ref="activity-form"
            class="flex-1 d-flex flex-column"
          >
            <div class="d-flex flex-1">
              <ant-input
                label="ID"
                style="max-width: 60px"
                class="mr-2"
                is-optional
              >
                <template #input-field>
                  <v-text-field
                    v-model="editActivity.activityId"
                    readonly
                    filled
                    single-line
                    dense
                  />
                </template>
              </ant-input>
              <ant-input label="Name" class="flex-1">
                <template #input-field>
                  <v-text-field
                    v-model="editActivity.name"
                    :rules="[rules.required, isUnique]"
                    filled
                    single-line
                    dense
                  />
                </template>
              </ant-input>
            </div>
            <ant-input label="Type">
              <template #input-field>
                <v-autocomplete
                  v-model="editActivity.activity_type"
                  :items="['construct', 'demolish', 'temporary']"
                  hide-details
                  filled
                  :rules="[rules.required]"
                  single-line
                  dense
                />
              </template>
            </ant-input>

            <div class="d-flex mt-5">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    :ripple="false"
                    class="mr-2 mt-5"
                    v-on="on"
                    @click="highlightInModel(editActivity.activityId)"
                  >
                    <v-icon class="ant-icon">
                      mdi-format-color-highlight</v-icon
                    >
                  </v-btn>
                </template>
                <span>Highlight in model</span>
              </v-tooltip>
              <ant-input
                class="flex-1"
                label="SBS"
                style="margin-top: 0 !important"
              >
                <template #input-field>
                  <v-autocomplete
                    v-model="editActivity.SBS"
                    :loading="isSbsLoading"
                    :items="sbsOptions"
                    :search-input.sync="sbsSearch"
                    item-text="code"
                    item-value="code"
                    hide-details
                    :rules="[rules.required]"
                    filled
                    persistent-placeholder
                    :placeholder="editActivity.SBS"
                    single-line
                    dense
                  />
                </template>
              </ant-input>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    :ripple="false"
                    class="ml-2 mt-5"
                    :color="selectSbsListener ? 'primary' : ''"
                    v-on="on"
                    @click="selectSbsFromModel"
                  >
                    <v-icon class="ant-icon">
                      mdi-image-size-select-small
                    </v-icon>
                  </v-btn>
                </template>
                <span>Select object in model</span>
              </v-tooltip>
            </div>

            <ant-input label="Dates">
              <template #input-field>
                <v-menu
                  ref="menu"
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateRange"
                      prepend-icon="mdi-calendar"
                      readonly
                      :rules="[rules.required]"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateRange"
                    no-title
                    scrollable
                    range
                    @input="setStartAndEndDate"
                  >
                  </v-date-picker>
                </v-menu>
              </template>
            </ant-input>

            <div class="d-flex">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    :disabled="!editActivity.predecessorId"
                    :ripple="false"
                    class="mr-2 mt-5"
                    v-on="on"
                    @click="highlightInModel(editActivity.predecessorId)"
                  >
                    <v-icon class="ant-icon">
                      mdi-format-color-highlight</v-icon
                    >
                  </v-btn>
                </template>
                <span>Highlight in model</span>
              </v-tooltip>
              <ant-input
                class="flex-1"
                label="Predecessor"
                is-optional
                style="margin-top: 0 !important"
              >
                <template #input-field>
                  <v-autocomplete
                    v-model="editActivity.predecessorId"
                    :items="
                      editActivities.filter(
                        (item) =>
                          item.id !== editActivity.id &&
                          item.activityId !== editActivity.successorId
                      )
                    "
                    prepend-inner-icon="mdi-ray-start"
                    item-text="name"
                    item-value="activityId"
                    hide-details
                    filled
                    single-line
                    clearable
                    dense
                  ></v-autocomplete>
                </template>
              </ant-input>
            </div>

            <div class="d-flex mt-5">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    :disabled="!editActivity.successorId"
                    :ripple="false"
                    class="mr-2 mt-5"
                    v-on="on"
                    @click="highlightInModel(editActivity.successorId)"
                  >
                    <v-icon class="ant-icon">
                      mdi-format-color-highlight</v-icon
                    >
                  </v-btn>
                </template>
                <span>Highlight in model</span>
              </v-tooltip>

              <ant-input
                class="flex-1"
                label="Successor"
                is-optional
                style="margin-top: 0 !important"
              >
                <template #input-field>
                  <v-autocomplete
                    v-model="editActivity.successorId"
                    :items="
                      editActivities.filter(
                        (item) =>
                          item.id !== editActivity.id &&
                          item.activityId !== editActivity.predecessorId
                      )
                    "
                    prepend-inner-icon="mdi-ray-end"
                    item-text="name"
                    item-value="activityId"
                    hide-details
                    filled
                    clearable
                    single-line
                    dense
                  ></v-autocomplete>
                </template>
              </ant-input>
            </div>

            <v-btn
              color="primary"
              elevation="0"
              class="mt-5"
              :loading="fourDisUpdating"
              :disabled="fourDisUpdating || fourDisDeleting"
              @click="saveActivity()"
            >
              <v-icon class="mr-2">mdi-content-save-outline</v-icon>
              Save
            </v-btn>
            <v-btn
              color="error"
              outlined
              class="mt-5"
              :loading="fourDisDeleting"
              :disabled="fourDisUpdating || fourDisDeleting"
              @click="deleteActivityDialog = true"
            >
              <v-icon class="mr-2">mdi-delete</v-icon>
              Delete
            </v-btn>
            <delete-dialog
              title="Are you sure you want to delete this activity"
              :dialog="deleteActivityDialog"
              @closeDialog="deleteActivityDialog = false"
              @deleteAction="deleteActivity()"
            />
          </v-form>
          <v-spacer v-if="editActivity" />
          <v-btn
            v-if="editPlanning"
            color="error"
            outlined
            small
            class="mt-5"
            @click="deletePlanningDialog = true"
          >
            <v-icon class="mr-2">mdi-delete</v-icon>
            Delete Planning
          </v-btn>
          <delete-dialog
            title="Are you sure you want to delete this planning"
            :dialog="deletePlanningDialog"
            @closeDialog="deletePlanningDialog = false"
            @deleteAction="deletePlanning()"
          />
        </div>
      </template>
    </forge-viewer>
    <panel-resizable
      v-if="toggle4DPlanning"
      ref="panelContainer"
      side="bottom"
      :collapsible="true"
      :default-height="400"
      class="ant-glass-background ant-border-top radius-0 d-flex flex-column"
      @resize-end="$refs['forge-viewer'].resizeViewEvent()"
      @collapse="
        toggle4DPlanning = false;
        $refs['forge-viewer'].resizeViewEvent();
      "
    >
      <div class="d-flex px-4 py-2 ant-border-bottom">
        <ant-input
          label="Planning"
          style="margin-top: 0 !important"
          is-optional
        >
          <template #input-field>
            <v-select
              v-model="selectedPlanningIds"
              :items="modelPlannings"
              item-text="name"
              item-value="id"
              multiple
              hide-details
              data-v-step="FourD_S1"
              filled
              single-line
              dense
              placeholder="Planning"
            />
          </template>
        </ant-input>
        <div class="flex-1 d-flex align-center mt-5 ml-4">
          <v-btn
            v-if="hasSelectedPlannings"
            color="primary"
            icon
            tile
            data-v-step="FourD_S2"
            class="mx-2"
            @click="isPlaying = !isPlaying"
          >
            <v-icon large>
              {{ isPlaying ? 'mdi-pause' : 'mdi-play' }}
            </v-icon>
          </v-btn>
          <v-slider
            v-if="!!sliderDate"
            v-model="daySlider"
            :step="playerInterval"
            :max="planningDuration"
            hide-details
          />
          {{ sliderDateText }}
          <div v-if="hasSelectedPlannings" class="mx-2">
            <v-edit-dialog large @save="updatePlayerInterval()">
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-icon color="primary" v-bind="attrs" v-on="on">
                    mdi-debug-step-over
                  </v-icon>
                  {{ playerInterval }}
                </template>
                <span>Edit player interval</span>
              </v-tooltip>
              <template #input>
                <v-text-field
                  v-model="updatedInterval"
                  label="Interval"
                  data-v-step="FourD_S3"
                  single-line
                  type="number"
                />
              </template>
            </v-edit-dialog>
          </div>
        </div>
      </div>
      <four-d-gantt-chart
        v-if="selectedPlannings.length > 0"
        :plannings="selectedPlannings"
        :slider-date="sliderDate"
        class="flex-1"
        @clickActivity="clickedActivity"
      />
    </panel-resizable>
    <v-tour
      :name="tourName"
      :steps="tourSteps"
      :callbacks="{
        onFinish: saveTourState,
        onSkip: saveTourState,
      }"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FOURD } from '@/modules/modules';
import moment from 'moment';
import PanelResizable from '@/components/Project/PanelResizable.vue';
import AntInput from '@/components/AntInput.vue';
import {
  getObjectsWithMappingLevel,
  hexToVector4,
} from '@/components/Modules/Daiwa-House-Modular-Europe/utils/DHME+utils';
import { searchSbsRecords } from '@/services/api/sbs.api';
import FourDGanttChart from '@/components/Modules/FourD/FourDGanttChart.vue';
import DeleteDialog from '@/components/DeleteDialog.vue';
import InputRulesMixin from '@/Mixins/InputRulesMixin';
import { fourDTour } from '@/lang/onboarding';
import CookieService from '@/services/cookie';

export default {
  name: 'FourD',
  components: {
    DeleteDialog,
    FourDGanttChart,
    AntInput,
    PanelResizable,
    ForgeViewer: () =>
      import(
        /* webpackChunkName: "fourD" */ '../components/Modules/Forge/ForgeViewer'
      ),
  },
  mixins: [InputRulesMixin],
  data: () => {
    return {
      searchPhrase: null,
      toggle4DPlanning: true,
      propertiesOfModels: [],

      editPlanning: null,
      editActivity: null,
      editActivityListModel: null,

      selectedPlanningIds: [],
      selectedPlannings: [],
      hasSelectedPlannings: false,

      // player
      isPlaying: false,
      daySlider: 0,
      sliderDate: undefined,
      sliderDateText: undefined,
      playerInterval: 1,
      updatedInterval: 1,

      sliderToken: undefined,
      sbsSearch: null,
      isSbsLoading: false,
      sbsOptions: [],

      dateMenu: false,
      dateRange: [],

      deleteActivityDialog: false,
      selectSbsListener: false,
      selectionBoundEvent: null,

      newPlanningDialog: false,
      newPlanningItem: {},

      deletePlanningDialog: false,

      //tour
      tourName: '4DTour',
      tourSteps: fourDTour('en'),
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'fourDData',
      'fourDStatus',
      'fourDPlannings',
      'fourDActivities',
      'fourDClient',
      'fourDModels',
      'fourDisUpdating',
      'fourDisDeleting',
      'selectedLicense',
    ]),
    modelPlannings() {
      const urns = this.fourDModels
        .filter((m) => !!m.enabled)
        .map((m) => m.urn);
      return this.fourDPlannings
        .filter((p) => urns.includes(p.modelURN))
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    editActivities() {
      return this.fourDActivities
        .filter((a) => a.planningId === this.editPlanning?.id)
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    planningDuration() {
      const dates = this.dates;

      return !!dates
        ? moment.duration(dates.end.diff(dates.start)).asDays() + 1
        : 0;
    },
    dates() {
      const activities = this.fourDActivities
        .filter((a) => this.selectedPlanningIds.includes(a.planningId))
        .map((a) => ({
          ...a,
          start_date: moment(a.start_date),
          end_date: moment(a.end_date),
        }));

      const start = activities
        .map((a) => a.start_date)
        .sort((l, r) => l.diff(r))[0];
      const end = activities
        .map((a) => a.end_date)
        .sort((l, r) => l.diff(r))
        .reverse()[0];

      return !!start && !!end && start !== end ? { start, end } : undefined;
    },
    forgeViewer() {
      return this.$refs['forge-viewer'].viewerService.Viewer3D;
    },
    forgeViewerService() {
      return this.$refs['forge-viewer'].viewerService;
    },
    modelProperties() {
      return this.$refs['forge-viewer'].modelObjects.flatMap(
        (item) => item.properties
      );
    },
  },
  watch: {
    modelPlannings(value) {
      this.selectedPlanningIds = [];
    },
    editActivityListModel(value) {
      if (value) {
        this.editActivity = { ...value };
        if (this.$refs['activity-form']) {
          this.$refs['activity-form'].resetValidation();
        }
      }
    },
    editActivity(value) {
      this.dateRange = [];
      if (value) {
        this.dateRange.push(moment(value.start_date).format('YYYY-MM-DD'));
        this.dateRange.push(moment(value.end_date).format('YYYY-MM-DD'));
      }
    },
    async sbsSearch(val) {
      if (val) {
        this.isSbsLoading = true;
        this.sbsOptions = await searchSbsRecords(this.project.id, val, {});
        this.isSbsLoading = false;
      }
    },
    selectedPlanningIds(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.daySlider = 0;
        this.invalidateSelectedPlannings();
        this.invalidateSlider();
      }
    },
    isPlaying(value) {
      if (value) {
        if (this.daySlider === this.planningDuration) {
          this.daySlider = 0;
        }
        this.$refs['forge-viewer'].viewerService.clearViewWithoutFit();
        this.startSliding();
      } else {
        clearInterval(this.sliderToken);
      }
    },
    daySlider(newValue, oldValue) {
      if (newValue >= this.planningDuration) {
        this.isPlaying = false;
      } else {
        if (newValue !== oldValue) {
          this.invalidateSlider();

          if (newValue - oldValue !== 1) {
            // slider moved not by player
            this.$refs['forge-viewer'].viewerService.clearViewWithoutFit();
          }
          this.renderActivitiesInModel();
        }
      }
    },
  },
  async mounted() {
    await this.$store.dispatch('loadFourDModuleData', {
      projectId: this.project.id,
      moduleId: this.project.modules.find((module) => module.route === FOURD)
        .id,
      sessionId: this.$route.params.sessionId ?? null,
    });
  },
  methods: {
    saveTourState() {
      CookieService._setCookie(this.tourName, 1);
    },
    async viewerLoaded(value) {
      await this.$refs['forge-viewer'].setExternalMapping(value.myData.urn);
      if (
        !CookieService._getCookie(this.tourName) &&
        this.selectedLicense.id === '911e928c-47f8-11ee-977b-0242ac120009'
      ) {
        this.$tours[this.tourName].start();
      }
    },
    isUnique(value) {
      // if(this.editActivity === value) {
      //   return true
      // }
      return (
        !this.editActivities
          .filter((item) => item.id !== this.editActivity.id)
          .some((item) => item.name === value) || 'Name already exists'
      );
    },
    async createPlanning() {
      const model = this.fourDModels.find((m) => !!m.enabled);
      this.newPlanningItem.modelURN = model.urn;
      try {
        this.editPlanning = await this.$store.dispatch(
          'createPlanning',
          this.newPlanningItem
        );
        this.newPlanningItem = {};
        this.newPlanningDialog = false;
      } catch (e) {}
    },
    setupNewActivity() {
      this.editActivityListModel = null;
      this.dateRange = [];
      this.editActivity = {
        activityId: this.editActivities.length + 1,
        planningId: this.editPlanning.id,
      };
      if (this.$refs['activity-form']) {
        this.$refs['activity-form'].resetValidation();
      }
    },
    unloadSbsSelectionEvent() {
      if (this.selectSbsListener) {
        this.selectSbsListener = false;
        this.forgeViewer.removeEventListener(
          Autodesk.Viewing.SELECTION_CHANGED_EVENT,
          this.selectionBoundEvent
        );
        this.forgeViewer.clearSelection();
        this.forgeViewer.clearThemingColors();
        this.selectionBoundEvent = null;
      }
    },
    sbsSelectionEvent() {
      let selection = this.forgeViewer.getSelection();

      let externalIds =
        this.$refs['forge-viewer'].getExternalIdsByNewObjectIds(selection);

      let objectsSelection = this.modelProperties.filter((object) => {
        return externalIds.some((id) => id === object.externalId);
      });

      let modelRecord = this.fourDModels.find(
        (m) => m.urn === this.editPlanning.modelURN
      );
      let sbsLevel = modelRecord.sbs_parameter.split('.');

      if (objectsSelection.length === 1) {
        this.editActivity.SBS = sbsLevel.reduce(
          (o, i) => o[i],
          objectsSelection[0].properties
        );

        this.sbsSearch = this.editActivity.SBS;
      }
    },
    selectSbsFromModel() {
      if (!this.selectSbsListener) {
        this.selectSbsListener = true;
        this.selectionBoundEvent = this.sbsSelectionEvent.bind(this);
        this.forgeViewer.addEventListener(
          Autodesk.Viewing.SELECTION_CHANGED_EVENT,
          this.selectionBoundEvent
        );
      } else {
        this.unloadSbsSelectionEvent();
      }
    },
    async saveActivity() {
      if (this.$refs['activity-form'].validate()) {
        if (this.editActivity.id) {
          await this.$store.dispatch('fourDUpdateActivity', this.editActivity);
        } else {
          await this.$store.dispatch('fourDCreateActivity', this.editActivity);
        }
        if (this.$refs['activity-form']) {
          this.$refs['activity-form'].resetValidation();
        }
      } else {
        this.$store.commit('showNotification', {
          content: 'Not all required fields are filled',
          color: 'warning',
        });
      }
    },
    async deleteActivity() {
      try {
        await this.$store.dispatch('fourDDeleteActivity', this.editActivity);
        this.editActivity = null;
        this.deleteActivityDialog = false;
      } catch (e) {}
    },
    async deletePlanning() {
      try {
        await this.$store.dispatch('fourDDeletePlanning', this.editPlanning);
        this.editPlanning = null;
        this.editActivity = null;
        this.deletePlanningDialog = false;
      } catch (e) {}
    },
    highlightInModel(activityId) {
      const sbscode = this.editActivities.find(
        (a) => a.activityId === activityId
      ).SBS;

      let model = this.forgeViewer.impl
        .modelQueue()
        .getModels()
        .concat()
        .find((model) => model.myData.urn === this.editPlanning.modelURN);

      let modelRecord = this.fourDModels.find(
        (m) => m.urn === this.editPlanning.modelURN
      );
      let sbsLevel = modelRecord.sbs_parameter.split('.');
      let objects = getObjectsWithMappingLevel(this.modelProperties, sbsLevel);

      let tmpObjects = objects.filter(
        (object) =>
          sbsLevel.reduce((o, i) => o[i], object.properties) === sbscode
      );

      let tmpIds = this.$refs[
        'forge-viewer'
      ].mapExternalIdsToObjectIdsMultiModel(
        modelRecord.urn,
        tmpObjects.map((object) => object.externalId)
      );

      this.forgeViewer.select(tmpIds, model);
    },
    setStartAndEndDate(item) {
      this.editActivity.start_date = item[0] ?? null;
      this.editActivity.end_date = item[1] ?? null;

      if (item.length === 2) {
        this.dateRange = [];
        this.dateRange.push(
          moment(this.editActivity.start_date).format('YYYY-MM-DD')
        );
        this.dateRange.push(
          moment(this.editActivity.end_date).format('YYYY-MM-DD')
        );
        this.dateMenu = false;
      }
    },
    updatePlayerInterval() {
      this.playerInterval = parseInt(this.updatedInterval);
    },
    invalidateSelectedPlannings() {
      const selectedPlannings = this.modelPlannings.filter((p) =>
        this.selectedPlanningIds.some((id) => id === p.id)
      );

      this.selectedPlannings = selectedPlannings;
      this.hasSelectedPlannings = selectedPlannings.length > 0;
    },
    invalidateSlider() {
      const lastDate = this.dates?.start?.clone()?.add(this.daySlider, 'days');

      this.sliderDate = lastDate;
      this.sliderDateText = lastDate?.format('DD-MM-YYYY');
    },
    startSliding() {
      this.sliderToken = setInterval(
        () => (this.daySlider += this.playerInterval),
        1000
      );
    },
    clickedActivity(id) {
      const activity = this.fourDActivities
        .filter((a) => this.selectedPlanningIds.includes(a.planningId))
        .find((a) => a.id === id);

      let selectedPlanning = this.selectedPlannings.find(
        (x) => x.id === activity.planningId
      );

      let modelRecord = this.fourDModels.find(
        (m) => m.urn === selectedPlanning.modelURN
      );

      let sbsLevel = modelRecord.sbs_parameter.split('.');

      let objects = getObjectsWithMappingLevel(this.modelProperties, sbsLevel);

      // show objects
      let isolateObjects = objects.filter(
        (object) =>
          sbsLevel.reduce((o, i) => o[i], object.properties) === activity.SBS
      );

      let model = this.forgeViewer.impl
        .modelQueue()
        .getModels()
        .concat()
        .find((model) => selectedPlanning.modelURN.includes(model.myData.urn));

      let isolateIds = this.$refs[
        'forge-viewer'
      ].mapExternalIdsToObjectIdsMultiModel(
        model.myData.urn,
        isolateObjects.map((object) => object.externalId)
      );

      this.forgeViewer.isolate(isolateIds, model);
      this.forgeViewer.fitToView(isolateIds, model);
    },
    renderActivitiesInModel() {
      if (this.toggle4DPlanning) {
        this.selectedPlannings.forEach((planning) => {
          let modelRecord = this.fourDModels.find(
            (m) => m.urn === planning.modelURN
          );

          let model = this.forgeViewer.impl
            .modelQueue()
            .getModels()
            .concat()
            .find((model) => planning.modelURN.includes(model.myData.urn));

          let modelObjects = this.$refs['forge-viewer'].modelObjects.find(
            (item) => item.urn === modelRecord.urn
          ).properties;

          let sbsLevel = modelRecord.sbs_parameter.split('.');
          let objects = getObjectsWithMappingLevel(modelObjects, sbsLevel);

          let activities = this.fourDActivities.filter(
            (a) => a.planningId === planning.id
          );

          activities.forEach((activity) => {
            const start = moment(activity.start_date);
            const end = moment(activity.end_date);

            if (activity.activity_type !== '') {
              if (
                this.sliderDate.isSameOrBefore(end) &&
                this.sliderDate.isAfter(start)
              ) {
                switch (activity.activity_type) {
                  case 'temporary':
                    let tmpObjects = objects.filter(
                      (object) =>
                        sbsLevel.reduce((o, i) => o[i], object.properties) ===
                        activity.SBS
                    );

                    let tmpIds = this.$refs[
                      'forge-viewer'
                    ].mapExternalIdsToObjectIdsMultiModel(
                      model.myData.urn,
                      tmpObjects.map((object) => object.externalId)
                    );

                    tmpIds.forEach((id) => {
                      this.forgeViewer.show(id, model);
                      this.forgeViewer.setThemingColor(
                        id,
                        hexToVector4('#DB9D4E'),
                        model,
                        true
                      );
                    });
                    break;
                  case 'construct':
                    let constructObjects = objects.filter(
                      (object) =>
                        sbsLevel.reduce((o, i) => o[i], object.properties) ===
                        activity.SBS
                    );

                    let constructIds = this.$refs[
                      'forge-viewer'
                    ].mapExternalIdsToObjectIdsMultiModel(
                      model.myData.urn,
                      constructObjects.map((object) => object.externalId)
                    );

                    constructIds.forEach((id) => {
                      this.forgeViewer.show(id, model);
                      this.forgeViewer.setThemingColor(
                        id,
                        hexToVector4('#149e95'),
                        model,
                        true
                      );
                    });
                    break;
                  case 'demolish':
                    let demoObjects = objects.filter(
                      (object) =>
                        sbsLevel.reduce((o, i) => o[i], object.properties) ===
                        activity.SBS
                    );

                    let demoIds = this.$refs[
                      'forge-viewer'
                    ].mapExternalIdsToObjectIdsMultiModel(
                      model.myData.urn,
                      demoObjects.map((object) => object.externalId)
                    );

                    demoIds.forEach((id) => {
                      this.forgeViewer.show(id, model);
                      this.forgeViewer.setThemingColor(
                        id,
                        hexToVector4('#E44550'),
                        model,
                        true
                      );
                    });
                    break;
                }
              } else if (this.sliderDate.isSameOrBefore(start)) {
                if (activity.activity_type !== 'demolish') {
                  // hide objects
                  let hideObjects = objects.filter(
                    (object) =>
                      sbsLevel.reduce((o, i) => o[i], object.properties) ===
                      activity.SBS
                  );

                  let hideIds = this.$refs[
                    'forge-viewer'
                  ].mapExternalIdsToObjectIdsMultiModel(
                    model.myData.urn,
                    hideObjects.map((object) => object.externalId)
                  );

                  hideIds.forEach((id) => {
                    this.forgeViewer.hide(id, model);
                  });
                }
              } else if (this.sliderDate.isSameOrAfter(end)) {
                if (activity.activity_type === 'construct') {
                  // show objects
                  let showObjects = objects.filter(
                    (object) =>
                      sbsLevel.reduce((o, i) => o[i], object.properties) ===
                      activity.SBS
                  );

                  let showIds = this.$refs[
                    'forge-viewer'
                  ].mapExternalIdsToObjectIdsMultiModel(
                    model.myData.urn,
                    showObjects.map((object) => object.externalId)
                  );

                  showIds.forEach((id) => {
                    this.forgeViewer.show(id, model);
                    this.forgeViewer.setThemingColor(id, null, model, true);
                  });
                } else {
                  // hide objects
                  let hideObjects = objects.filter(
                    (object) =>
                      sbsLevel.reduce((o, i) => o[i], object.properties) ===
                      activity.SBS
                  );

                  let hideIds = this.$refs[
                    'forge-viewer'
                  ].mapExternalIdsToObjectIdsMultiModel(
                    model.myData.urn,
                    hideObjects.map((object) => object.externalId)
                  );

                  hideIds.forEach((id) => {
                    this.forgeViewer.hide(id, model);
                  });
                }
              }
            }
          });
        });
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
